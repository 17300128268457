// Gatsby supports TypeScript natively!
// @ts-ignore
import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = (props: PageProps) => (
  <Layout>
    <SEO title="About" />
    <p>Wouldn't you like to know</p>
  </Layout>
)

export default About
